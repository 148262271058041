import React, { useEffect, useRef } from 'react';
import './HamburgerMenu.scss';
import schwab_logo from '../../images/schwab.png';
import Scheduler from '../Scheduler/ScheduleButton';
import SIFS_logo from '../../images/SIFS_logo_white.png';

const HamburgerMenu: React.FC = () => {
  const menuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    function handleClick(event: MouseEvent) {
      const checkbox = document.getElementById('menu__toggle') as HTMLInputElement;
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement)?.closest('.small-menu__btn')
      ) {
        checkbox.checked = false;
      }
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <nav>
      <input id="menu__toggle" type="checkbox" />
      <label className="small-menu__btn" htmlFor="menu__toggle">
        <span></span>
      </label>
      <div className="small-logo-container">
        <a className="company-name" href='/'> 
          <img className='coLogoHam' src={SIFS_logo} alt="Company Logo" />
        </a>
      </div>
      <ul ref={menuRef} className="small-menu__box">
        <div className="links">
          <li><a className="small-menu__item" href="/">Home</a></li>
          <li><a className="small-menu__item" href="/about">About</a></li>
          <li><a className="small-menu__item" href="/services">Services</a></li>
          <li><a className="small-menu__item" href="/disclosures">Disclosures</a></li>
          <li>
            <a className="small-menu__item" href="https://schwaballiance.com/" target='_blank' rel="noreferrer">
              <img className='small-schwab_btn' src={schwab_logo} alt="" />
            </a>
          </li>
        </div>
        <div className="scheduleBtn_HamMenu">
          <Scheduler/>
        </div>
      </ul>
    </nav>
  );
}

export default HamburgerMenu;