import React from 'react';
import './PageNotFound.scss';

const PageNotFound: React.FC = () => {
  return (
    <div className="PageNotFoundBlock">
        <div id="notFound">
            <div className="notFound">
                <div className="notfound-404">
                    <h1>Oops!</h1>
                    <h2>404 - The Page can't be found</h2>
                </div>
                <a href="/">Go Back To Homepage</a>
            </div>
        </div>
    </div>
  );
};

export default PageNotFound;
