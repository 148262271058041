import React from 'react';
import AppRouter from './AppRouter';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import ContactModal from './components/ContactModal/ContactModal';

const App: React.FC = () => {


  return (
    <div className="appContainer">
        <Navbar />
        <AppRouter />
        <ContactModal />
        <Footer />
    </div>
  );
}

export default App;