import React, { useState } from 'react';
import './ScheduleButton.scss';

const ScheduleButton: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleScheduleMeeting = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="schedule-button-container">
      <button className="schedule-button" onClick={toggleDropdown}>
        Schedule a Meeting <span className="separator">|</span> <span className="dropdown-arrow">▼</span>
      </button>
      {showDropdown && (
        <div className="dropdown-menu">
          <div
            className="dropdown-item"
            onClick={() => handleScheduleMeeting('https://calendly.com/sifs-jarrett')}
          >
            Jarrett Sharp
          </div>
          <div
            className="dropdown-item"
            id='emmett'
            onClick={() => handleScheduleMeeting('https://calendly.com/sifs-emmett')}
          >
            Emmett Fehlau-Barton
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduleButton;