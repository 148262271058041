import React, {useState, useEffect} from 'react';
import './Navbar.scss';
import Scheduler from '../Scheduler/ScheduleButton';
import HamMenu from '../HamburgerMenu/HamburgerMenu';
import schwab_logo from '../../images/schwab.png';
import SIFS_logo from '../../images/SIFS_logo_white.png'

const Navbar: React.FC = () => {

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 790);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isSmallScreen) {
    return (
      <div>
        <div className="small-navbar-container">
          <HamMenu/>
        </div>
      </div>
    );
  }


  return (
    <div>
      <div className="navbar-container">
        <div className="logo-container">
          <a className="company-name" href='/'> 
            <img className='coLogo' src={SIFS_logo} alt="Company Logo" />
          </a>
        </div>
        <Scheduler />
        <ul className="menu__box">
          <li>
            <a className="menu__item" id='home' href="/">HOME</a>
          </li>
          <li className="dropdown">
            <a className="menu__item" href="/about">ABOUT</a>
            <ul className="dropdown-content">
              <li><a id='dropLink' href="/about/Jarrett_Sharp">Jarrett S.</a></li>
              <li><a id='dropLink' href="/about/Emmett_Fehlau-Barton">Emmett F.</a></li>
            </ul>
          </li>
          <li>
            <a className="menu__item" href="/services">SERVICES</a>
          </li>
          <li className="dropdown">
            <a className="menu__item" href="/disclosures">DISCLOSURES</a>
            <ul className="dropdown-content">
              <li><a id='dropLink' href={require("../../documents/Form ADV Part 2A.pdf")} target="_blank" rel="noopener noreferrer">Form ADV Part 2A</a></li>
              <li><a id='dropLink' href={require("../../documents/SIFS_Privacy_Policy.pdf")} target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
            </ul>
          </li>
          <a href="https://client.schwab.com/Areas/Access/Login?&kc=y&sim=y" target='_blank' rel="noreferrer">
            <img className='schwab_Btn' src={schwab_logo} alt="Go To Schwab Button" />
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
