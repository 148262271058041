import React from 'react';
import "./Disclosures.scss";
import Mountains from '../../images/mountains.png';

const Disclosures: React.FC = () => {
  return (
    <div>
        <title>Disclosures | Sharper Image Financial Services</title>
        <div className="disclosures-container">
            <h1 className="disclosures-header">Disclosures</h1>
            <ul className="disclosures-list">
                <li className="disclosures-item">
                <a className="disclosures-link" href={require("../../documents/Form ADV Part 2A.pdf")} target="_blank" rel="noopener noreferrer">Form ADV Part 2A</a>
                </li>
                <li className="disclosures-item">
                <a className="disclosures-link" href={require("../../documents/SIFS_Privacy_Policy.pdf")} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </li>
            </ul>
        </div>
        <img src={Mountains} alt="" className="MountainFooter" />
    </div>

  );
};

export default Disclosures;
