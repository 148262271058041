import React from 'react';
import './Footer.scss';
import BrokerCheckLogo from '../../images/brokercheck-finra-logo.png'

import Arrow from '../../images/footer_arrow.png';

const Footer: React.FC = () => {
  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footerContainer">
      <div>
        <h1 className="companyName">Sharper Image Financial Services</h1>
        <h2 className="copyright">Sharper Image Financial Services, LLC <span>&copy; 2024</span></h2>
        <div className="linksContainer">
          <a href="https://adviserinfo.sec.gov/firm/summary/330048" target='_blank' rel="noreferrer" className="brokerCheckLink">
            <img className='bcLogo' src={BrokerCheckLogo} alt="Link To BrokerCheck" />
          </a>
        </div>
        <p className="disclosureText">
          Sharper Image Financial Services, LLC (SIFS) is a registered investment adviser located in Erie, Colorado. SIFS may only transact business 
          in those states in which it is registered or qualifies for an exemption or exclusion from registration requirements. SIFS's website is 
          limited to the dissemination of general information pertaining to its advisory services, together with access to additional investment-related 
          information, publications, and links. Accordingly, the publication of SIFS&aposs website on the Internet should not be construed by any consumer 
          and/or prospective client as SIFS's solicitation to effect, or attempt to effect transactions in securities, or the rendering of personalized 
          investment advice for compensation, over the Internet. Any subsequent, direct communication by SIFS with a prospective client shall be 
          conducted by a representative that is either registered or qualifies for an exemption or exclusion from registration in the state where the 
          prospective client resides. For information pertaining to the registration status of SIFS, please contact the state securities regulators for 
          those states in which SIFS maintains a registration filing. A copy of SIFS's current written disclosure statement discussing SIFS's business 
          operations, services, and fees is available at the SEC's investment adviser public information website - www.adviserinfo.sec.gov or from SIFS 
          upon written request. SIFS does not make any representations or warranties as to the accuracy, timeliness, suitability, completeness, or 
          relevance of any information prepared by any unaffiliated third party, whether linked to SIFS's web site or incorporated herein and takes no 
          responsibility therefor. All such information is provided solely for convenience purposes only and all users thereof should be guided 
          accordingly.
          This website and information are provided for guidance and information purposes only.  Investments involve risk and unless otherwise stated, 
          are not guaranteed. Be sure to first consult with a qualified financial adviser and/or tax professional before implementing any strategy. 
          This website and information are not intended to provide investment, tax, or legal advice.
          There are no warranties implied.
        </p>
      </div>
      <img
        id="scrollTopButton"
        className="scrollTopButton"
        src={Arrow}
        alt="Scroll to Top"
        title="Scroll to Top"
        onClick={handleScrollTop}/>
    </footer>
  );
};

export default Footer;