import React, { useState } from 'react';
import './ContactModal.scss';

import MSG_Icon from '../../images/message.png';
import FormImg from '../../images/boulder.png';
import Select from '../../images/select_arrow.png';

const ContactModal: React.FC = () => {
  const [showBox, setShowBox] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [showOtherInput, setShowOtherInput] = useState<boolean>(false);
  const [otherValue, setOtherValue] = useState<string>('');
  const [selectedReceiver, setSelectedReceiver] = useState<string>('');

  const handleButtonClick = () => {
    setShowBox(true);
  };

  const handleBoxClose = () => {
    setShowBox(false);
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(/\D/g, '');

    let formattedPhoneNumber = '';

    if (numericValue.length >= 3) {
      formattedPhoneNumber += `(${numericValue.slice(0, 3)}`;
    } else {
      formattedPhoneNumber += numericValue;
    }

    if (numericValue.length >= 6) {
      formattedPhoneNumber += `) ${numericValue.slice(3, 6)}`;
    } else if (numericValue.length > 3) {
      formattedPhoneNumber += `) ${numericValue.slice(3)}`;
    }

    if (numericValue.length >= 10) {
      formattedPhoneNumber += `-${numericValue.slice(6, 10)}`;
    } else if (numericValue.length > 6) {
      formattedPhoneNumber += `-${numericValue.slice(6)}`;
    }

    setPhoneNumber(formattedPhoneNumber);
  };

  const handleSurveyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === "Other") {
      setShowOtherInput(true);
    } else {
      setShowOtherInput(false);
      setOtherValue('');
    }
  };

  const handleReceiverChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedReceiver(event.target.value);
  };

  const getApiKey = () => {
    const apiKey = selectedReceiver === 'JS'
      ? process.env.REACT_APP_JARRETT_API_KEY
      : selectedReceiver === 'EF'
      ? process.env.REACT_APP_EMMETT_API_KEY
      : '';
    return apiKey;
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    console.log('Form Data:', Object.fromEntries(formData.entries())); // Log the entire form data

    // Check if the access key is valid
    const apiKey = formData.get('access_key');
    if (!apiKey) {
      console.error('No API Key provided!');
      return;
    }

    event.currentTarget.submit(); // Submit the form
  };

  return (
    <div>
      {!showBox && (
        <button className="circularButton" onClick={handleButtonClick}>
          <span className="tooltip"><span className='tooltipText'>Contact Us</span></span>
          <img className='contactImg' src={MSG_Icon} alt="Contact Us Button" />
        </button>
      )}
      {showBox && (
        <div className="box">
          <h1 className="boxTitle">Contact Us</h1>
          <form 
            className='contactModalForm' 
            action="https://api.web3forms.com/submit" 
            method="POST" 
            onSubmit={handleFormSubmit}
          >
            <input type="hidden" name="access_key" value={getApiKey()} />
            <input
              id='formName'
              type="text"
              name='name'
              placeholder='Full Name'
              required
            />
            <input
              id='formEmail'
              type="email"
              name='email'
              placeholder='E-Mail'
              required
            />
            <input
              id='formPhone'
              type="text"
              name='phone'
              placeholder='Phone Number'
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
            <textarea
              id='formMessage'
              name='message'
              placeholder='MESSAGE'
              required
            />
            <select
              id="formSelect"
              name='receiver'
              onChange={handleReceiverChange}
              required
            >
              <option disabled selected value="">Who Would You Prefer To Contact? <img className='formArrow' src={Select} alt="Dropdown Arrow" /></option>
              <option value="JS">Jarrett Sharp</option>
              <option value="EF">Emmett Fehlau-Barton</option>
            </select>
            <select
              id="formSelect"
              name='survey'
              onChange={handleSurveyChange}
            >
              <option disabled selected value="">How Did You Hear About Us? <img className='formArrow' src={Select} alt="Dropdown Arrow" /></option>
              <option value="Family/Friends">Family/Friends</option>
              <option value="Google Search">Google Search</option>
              <option value="Advertisements">Advertisements</option>
              <option value="Other">Other</option>
            </select>
            {showOtherInput && (
              <input
                id='formSpecify'
                type="text"
                name='otherInput'
                placeholder='Please Specify'
                value={otherValue}
                onChange={(e) => setOtherValue(e.target.value)}
              />
            )}
            <button id='submitBtn' type="submit">Send →</button>
          </form>
          <img src={FormImg} alt="" className="formImg" />
          <button className="closeButton" onClick={handleBoxClose}>
            X
          </button>
        </div>
      )}
    </div>
  );
};

export default ContactModal;