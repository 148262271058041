import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Services.scss';
import FPImage from '../../images/fpImage.png';
import IAImage from '../../images/iaImage.png';
import PBImage from '../../images/pbImage.png';

import Mountains from '../../images/mountains.png'


const Services: React.FC = () => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1250);
    };
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isSmallScreen) {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };

    return (
      <div className='services-container-small'>
        <title>Services | Sharper Image Financial Services</title>
        <h1 className='servicePageTitle-small'>Services <span>with Free Initial Consultation</span></h1>
        <div className="services-small">
          <Slider {...settings}>
            <div className="center-slider-content-small">
              <div className="financialPlanning-small">
                <img src={FPImage} alt="" className="fpImage-small" />
                <div className="fpInfo-small">
                  <h1 className="fpTitle-small"><i>Comprehensive Financial Planning</i></h1>
                  <p className="fpDesc-small">Whether you are looking to buy a home or wanting to retire, we build financial plans tailored 
                  specifically to your goals and needs. We take into consideration all aspects of your financial life, and use that knowledge to help 
                  you achieve those goals. This is a fun and engaging process!</p>
                </div>
              </div>
            </div>

            <div className="center-slider-content-small">
              <div className="investmentAdv-small">
                <img src={IAImage} alt="" className="iaImage-small" />
                <div className="iaInfo-small">
                  <h1 className="iaTitle-small"><i>Portfolio Management Services</i></h1>
                  <p className="iaDesc-small">Because everyone is different, there are many ways to approach building an investment portfolio. 
                  We emphasize diversification, providing you with comfort when times are tough but also taking advantage of when things are good. 
                  We take into consideration risk tolerance, tax situations, and future goals to build the optimal investment plan just for you.</p>
                </div>
              </div>     
            </div>

            <div className="center-slider-content-small">
              <div className="pbPlanning-small">
                <img src={PBImage} alt="" className="pbPlanImage-small" />
                <div className="pbPlanInfo-small">
                  <h1 className="pbPlanTitle-small"><i>Project-Based Financial Planning</i></h1>
                  <p className="pbPlanDesc-small">Not everyone needs the full service of a regular holistic financial plan. Our project-based planning 
                  offers clients the ability to ask for more simple services, like planning to buy a house, while keeping your cost low.</p>
                </div>
              </div>           
            </div>
          </Slider>
          <img className="mountainImg-small" src={Mountains} alt="backdrop" />
        </div>
      </div>
    );
  }

  return (
    <div className='services-container'>
      <title>Services | Sharper Image Financial Services</title>
      <h1 className='servicePageTitle'>Services <span>with Free Initial Consultation</span></h1>
      <div className="services">
          <div className="financialPlanning">
            <img src={FPImage} alt="" className="fpImage" />
            <div className="fpInfo">
              <h1 className="fpTitle"><i>Comprehensive Financial Planning</i></h1>
              <p className="fpDesc">Whether you are looking to buy a home or wanting to retire, we build financial plans tailored specifically to your 
              goals and needs. We take into consideration all aspects of your financial life, and use that knowledge to help you achieve those goals. 
              This is a fun and engaging process!
              </p>
            </div>
          </div>

          <div className="investmentAdv">
            <img src={IAImage} alt="" className="iaImage" />
            <div className="iaInfo">
              <h1 className="iaTitle"><i>Portfolio Management Services</i></h1>
              <p className="iaDesc">Because everyone is different, there are many ways to approach building an investment portfolio. 
              We emphasize diversification, providing you with comfort when times are tough but also taking advantage of when things are good. 
              We take into consideration risk tolerance, tax situations, and future goals to build the optimal investment plan just for you.</p>
            </div>
          </div>

          <div className="pbPlanning">
            <img src={PBImage} alt="" className="pbPlanImage" />
            <div className="pbPlanInfo">
              <h1 className="pbPlanTitle"><i>Project-Based Financial Planning</i></h1>
              <p className="pbPlanDesc">Not everyone needs the full service of a regular holistic financial plan. Our project-based planning 
              offers clients the ability to ask for more simple services, like planning to buy a house, while keeping your cost low.</p>
            </div>
          </div>
          <img className="mountainImg" src={Mountains} alt="backdrop" />
      </div>
    </div>
  );
};

export default Services;
